import React from 'react'
import Layout from "../components/layout"
import suspects from '../images/suspects-suspected.png'
import dogberry from '../images/dogberry-day-11.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Suspects suspected" image={suspects} />
            <h1>
                Suspects suspected
            </h1>
            <img src={suspects} />
            <p>In an animated interview earlier this morning, Dogberry announced that he believes he knows who captured the images of Margaret in a compromising position.</p>
                
            <p>He revealed that he is tracking the men today, but declined to release the names of the suspects.</p> 

            <p>“I can assuage you that I’m hot on their tails,” Dogberry declared. The Messenger feels assured that Dogberry will be more forthcoming with information once his pursuit of the suspects’ trails has come to a close. At which point, we hope to discover that the suspects do not, in actuality, have tails.</p>

            <img className="dogberry-ad" src={dogberry} />

        </Layout>
    )
}
